.dropzone {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border: 1px #ced4da dashed;
    border-radius: 10px;
    width: 100%;
    margin: auto;
}

aside ul{
    padding: 0px;
    width: 100%;
    margin: auto;
}