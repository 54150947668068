@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    pointer-events: none;
    animation: spinner 1.5s linear infinite;
}

.loadingscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255);
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 100000000;

}
.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 100000000;

}
button.likelink {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #6c757d;
    cursor: pointer;
}
.progress-wrapper{
    margin: auto;
    width: 500px;
    z-index: 50000000000;
    pointer-events: none;
}
.progress-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
}


